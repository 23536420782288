/* Login.css */
input::placeholder {
    color: #cfcfcf;  /* Lysere placeholder-tekst */
    opacity: 1;      /* Full opacity */
  }
  
  .ant-input::placeholder, .ant-input-password input::placeholder {
    color: #cfcfcf;  /* Lysere placeholder for Ant Design Inputs */
    opacity: 1;      /* Full opacity for synligheten */
  }
  