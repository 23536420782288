.outer-wrapper {
  padding: 15px;
  background-color: #1e1e1e;
  border-radius: 10px;
  border: 1px solid #6a6a6a;
  width: 100%;  /* Full bredde */
  max-width: 1200px;  /* Maks bredde */
  margin: 10px 0;  /* Justering for avstand fra toppen og bunnen */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  position: relative;
  left: 0;  /* Plasser til venstre */
  transition: background-color 0.1s ease-in-out; /* Overgangseffekt for bakgrunnsfargen */
}

.fade-in {
  background-color: #777777; /* Fargen under fade-effekt */
  transition: background-color 0.1s ease-in-out;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  width: 100%;
}

.grid-item {
  background-color: #333333;
  padding: 15px;
  border-radius: 8px;
  color: white;
  border: 1px solid #444444;
  transition: background-color 0.1s ease-in-out; /* Fade-effekt for grid-item */
}

.grid-item.fade-in {
  background-color: #555555; /* Lysere farge for fade-effekt */
}

.input-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.input-group label {
  min-width: 120px; /* Fast bredde for label */
  margin-right: 10px;
  color: #bbbbbb;
}

.input-group input,
.input-group textarea {
  background-color: #444444;
  color: #ffffff;
  border: none;
  padding: 8px;
  border-radius: 4px;
  width: 100%;
  transition: background-color 0.1s ease-in-out; /* Fade-effekt for input- og textarea-felt */
}

.input-group input.fade-in,
.input-group textarea.fade-in {
  background-color: #555555; /* Lysere farge ved fading */
}

h3 {
  margin-bottom: 10px;
  color: #eeeeee;
}

.submit-button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  font-size: 14px;
}
